import React, { useState } from 'react';
import clsx from 'clsx';
import VideoPlayer from 'react-video-js-player';
import { Employee } from '../../common';

const VideoThumbnail = ({ src, small, className, employee }) => {
  const [showEmployee, setShowEmployee] = useState(true);
  return (
    <div
      className={clsx(
        'w-full h-40 mb-4 shadow-lg relative hover:opacity-99 cursor-pointer',
        className
      )}
    >
      <VideoPlayer controls src={src} onPlay={() => setShowEmployee(false)} />
      {showEmployee && (
        <div
          className={clsx(
            'absolute bottom-0 text-left text-white w-full bg-opacity-75 bg-black',
            small ? 'pl-2 py-2' : 'pl-4 py-4'
          )}
        >
          <Employee employee={employee} />
        </div>
      )}
    </div>
  );
};

export default VideoThumbnail;
