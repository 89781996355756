import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import ScrollTo from '../common/scroll-to';

const VideoHero = () => {
  if (typeof window === 'undefined') {
    return <div />;
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          timeline: file(relativePath: { regex: "/timeline.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ timeline }) => (
        <BackgroundImage
          Tag="section"
          id="hero h-screen"
          fluid={timeline.childImageSharp.fluid}
        >
          <section className="mt-10 md:pb-20">
            <div className="container mx-auto h-full mt-16">
              <div className="flex flex-wrap text-white text-center">
                <div className="w-full p-5 mt-32 mb-20 text-center">
                  <h1 className="font-serif text-5xl md:text-6xl">
                    Happy Birthday videos
                  </h1>
                  <p className="lg:max-w-2xl mx-auto mt-12 text-xl font-light mb-20">
                    Watch the best videos from our employees and customers!
                  </p>
                  <ScrollTo
                    to="videos"
                    offset={-200}
                    className="cursor-pointer"
                  >
                    <img
                      src="/icons/arrow_down.svg"
                      width="20"
                      className="mx-auto mt-10 transform hover:translate-y-2 duration-200"
                      alt="10 things we love most about Trax"
                    />
                  </ScrollTo>
                </div>
              </div>
            </div>
          </section>
        </BackgroundImage>
      )}
    />
  );
};

export default VideoHero;
