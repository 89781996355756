import React, { useState } from 'react';
import { graphql } from 'gatsby';
import VideoThumbnail from '../components/home/happy-birthday/video-thumbnail';
import { VideoHero } from '../components/video';

const VideosPage = ({ data }) => {
  const videos = data.allStrapiVideos.nodes[0].EmployeeVideo.filter(
    ({ video }) => video.length > 0
  );
  const [pagination, setPagination] = useState(4);
  const maxLength = videos.length;

  if (typeof window === 'undefined') {
    return <div />;
  }

  const increasePagination = () => {
    const nextPagination =
      pagination + 4 > maxLength ? maxLength : pagination + 4;

    setPagination(nextPagination);
  };

  return (
    <>
      <VideoHero />
      <section className="py-20" id="videos">
        <div className="container">
          <div className="md:flex md:flex-wrap">
            {videos.slice(0, pagination).map(({ video, id, employee }) => (
              <div className="p-5 md:w-1/2 lg:w-1/3 xl:w-1/4" key={id}>
                <VideoThumbnail
                  employee={employee}
                  src={video[0].url}
                  className="h-64"
                />
              </div>
            ))}
          </div>
          {pagination !== maxLength && (
            <div className="flex justify-center">
              <button
                type="button"
                className="py-3 px-10 transition duration-200 ease-in-out uppercase border-2 text-purple border-purple inline-block m-4 tracking-wider hover:bg-purple hover:text-white"
                onClick={() => increasePagination()}
              >
                Load more videos
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default VideosPage;

export const query = graphql`
  query MyQuery {
    allStrapiVideos {
      nodes {
        EmployeeVideo {
          id
          video {
            url
          }
          employee {
            id
            name
            position
          }
        }
      }
    }
  }
`;
